.chat-window {
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 70%;
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
  overflow-x: auto;
  margin-top: 30px;
}

.message-list {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.message-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.message-input input {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
}

.message-input button {
  padding: 10px 20px;
}

/* Custom scrollbar styles */
.message-list::-webkit-scrollbar {
  width: 6px;
  /* Smaller width */
}

.message-list::-webkit-scrollbar-track {
  background: #f0f2f5;
  /* Light grey track */
}

.message-list::-webkit-scrollbar-thumb {
  background: #ff8c00;
  /* Darker orange on hover */
  border-radius: 3px;
}

.address-title-url {
  background-color: #EFEFEF;
  text-decoration: none;
  color: #888;
  font-size: 0.6rem;
  border-radius: 3px;

}

@media (max-width: 768px) {
  .address-title-url {
      max-width: 120px; /* Adjust max-width for smaller screens */
  }
  .address-title-url {
    background-color: #EFEFEF;
    text-decoration: none;
    color: #888;
    font-size: 0.6rem;
    border-radius: 3px;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide the overflow */
    text-overflow: ellipsis; /* Add ellipsis (...) when the text overflows */
    max-width: 150px; /* Set a maximum width for the URL */
    display: inline-block; /* Display inline but respect width */
    vertical-align: middle; /* Align vertically in case of text wrapping */
  }
}

@media (max-width: 480px) {
  .address-title-url {
      max-width: 90px; /* Further adjust max-width for very small screens */
  }
  .address-title-url {
    background-color: #EFEFEF;
    text-decoration: none;
    color: #888;
    font-size: 0.6rem;

    border-radius: 3px;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide the overflow */
    text-overflow: ellipsis; /* Add ellipsis (...) when the text overflows */
    max-width: 150px; /* Set a maximum width for the URL */
    display: inline-block; /* Display inline but respect width */
    vertical-align: middle; /* Align vertically in case of text wrapping */
  }
}

.message-list::-webkit-scrollbar-thumb:hover {
  background: #ff8c00;
  /* Darker orange on hover */
}
.thread-title-container {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.url-avatar, .token-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-right: 10px;
}

.url-avatar {
  background-color: white;
  color: orange;
  font-weight: bold;
}

.token-logo {
  object-fit: cover;
}

.url-details, .token-details {
  display: flex;
  flex-direction: column;
}

.url-details h4, .token-details h4 {
  margin: 0;
  font-size: 18px;
}

.url-details p, .token-details p {
  margin: 0;
  font-size: 14px;
  color: gray;
}

.token-display , .url-display{
  display: flex;
}
/* Placeholder styles */
.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
}

.placeholder img {
  max-width: 300px;
  margin-bottom: 20px;
}


/* Modal styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.3s ease;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  animation: slideDown 0.5s ease;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
  font-size: 24px;
}

.modal-header button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.thread-title-container {
  display: flex;
  justify-content: space-between;
  background-color: #F0F0F0;
  padding: 15px;
  height: 60px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
}

textarea {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* .save-button {
  background: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.cancel-button {
  background: #f44336;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
} */

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-20%);
  }

  to {
    transform: translateY(0);
  }
}


@media (max-width: 768px) {
  .chat-window {

    width: 100%;
    max-width: 1200px;
    padding: 20px;
    margin: 0 auto;
    overflow-x: auto;
    margin-top: 30px;
  }
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.follow-filter-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.attention-message {
  background-color: #fff3cd; /* Light yellow background */
  border-left: 6px solid red; /* Yellow border to make it stand out */
  color: #856404; /* Dark yellow-brown text color for readability */
  padding: 16px; /* Add some padding */
  margin: 20px 0; /* Add margin to separate it from other content */
  font-size: 16px; /* Slightly larger font for emphasis */
  font-weight: 600; /* Bold font to draw attention */
  display: flex;
  align-items: center;
  border-radius: 4px; /* Rounded corners for a polished look */
}
