.custom-dropdown {
    position: relative;
    display: inline-block;
}

.custom-dropdown-button {
    /* background-color: #007bff; */
    color: white;
    /* padding: 10px; */
    font-size: 2rem;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

/* .custom-dropdown-button:hover {
    background-color: #0056b3;
} */

.custom-dropdown-content {
    position: absolute;
    right: 25px;
    top: 24px;
    background-color: white;
    color: black;
    border: 1px solid #ccc;
    min-height: 100px;
    width: 150px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.custom-dropdown-content button {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
}

.custom-dropdown-content button:hover {
    background-color: #ddd;
}

.custom-dropdown:hover .custom-dropdown-content {
    display: block;
}

