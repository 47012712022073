.detailed-repor {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow-x: auto;
}



.detailed-report table {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px;
    border: 1px solid #ddd;
}

.detailed-report table th,
.detailed-report table td {
    padding: 10px;
    text-align: left;
}

.detailed-report table th {
    background-color: #F8F9FA;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
}

.detailed-report table td {
    font-size: 0.8rem;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
}


.detailed_report_icon {
    fill: #00BDD6;
    padding-right: 5px;
    
}

.detail-report-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

}

.detailed-report-text {
    margin-top: 15px;
    font-size: 1.4rem;
    font-family: "Epilogue", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 600;
    color: #333;
}


.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    list-style-type: none;
    padding: 0;
}

.pagination li {
    margin: 0 5px;
}

.pagination li a {
    cursor: pointer;
    padding: 8px 16px;
    border: 1px solid #ddd;
    color: #007bff;
    text-decoration: none;
}

.pagination li a:hover {
    background-color: #007bff;
    color: white;
}

.pagination .active a {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
}

.delete-icon {

    color: red;
    cursor: pointer;

}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  .summary-report-header{
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid #ccc;
    margin-bottom: 20px;
  }

  .summary-tab{
    flex: 1;
    padding: 10px 0;
    cursor: pointer;
    position: relative;
    text-align: center;
  }

  .summary-tab.active {
    color:  #007bff;
    font-weight: bold;
}


.summary-tab.active::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: #007bff;
}

.recent-container-admin{
    display: flex;
    width: 100%;
    border-collapse: collapse;
    min-width: 600px;
    border: 1px solid #ddd;
}
.no-data-message {
    text-align: center;

    padding: 60px;
    font-size: 18px;
    color: #888;
}
