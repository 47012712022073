.search-component {
  position: relative;
  width: 60%; /* Wider on full screens */
  max-width: 800px; /* Maximum width on larger screens */
  margin: 0 auto; /* Center the search component on the page */
}

.search-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input-container input {
  width: 100%;
  padding: 10px 10px 10px 30px; /* Space for the search icon */
  border: 1px solid #a19595;
  border-radius: 4px;
  height: 50px;
  transition: border-color 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.search-input-container input:focus {
  border-color: #007bff;
  outline: none;
}

.search-icon {
  position: absolute;
  left: 10px;
  color: #ccc;
}

.clear-icon {
  position: absolute;
  right: 10px;
  color: #ccc;
  cursor: pointer;
  transition: color 0.3s ease;
}

.clear-icon:hover {
  color: #ff0000;
}

.loading-circle {
  width: 20px;
  height: 20px;
  border: 3px solid #ccc;
  border-top: 3px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 10px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.search-dropdown {
  /* position: absolute; */
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background: white;
  z-index: 1000;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.search-dropdown-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background 0.3s ease;
}

.search-dropdown-item img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.search-dropdown-item span {
  font-weight: 500;
  color: #333;
}

.search-dropdown-item:hover {
  background: #f8f9fa;
}

.no-results {
  color: #999;
  text-align: center;
  padding: 10px;
  font-style: italic;
}

.image-token{
  height: 400px;
  width: 400px;
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .search-component {
    width: 100%; /* Full width on small screens */
    max-width: 100%; /* Ensure it covers the full width of the viewport */
    padding: 0 15px; /* Add some padding for better spacing */
  }

  .search-dropdown {
    max-height: 150px; /* Reduce height to fit smaller screens */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .search-component {
    width: 80%; /* Slightly narrower on tablet-sized screens */
  }
}

@media (min-width: 1025px) {
  .search-component {
    width: 60%; /* Wider on large screens */
  }
}
