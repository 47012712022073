.notification-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
}

.notification-title {
    padding: 20px;
    margin: auto;
    overflow-x: auto;
    margin-top: 30px;
    margin-left: 4%;
}

.notification-container {
    width: 90%;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 8px;
    padding: 20px;
    height: 80%;
    overflow-y: auto;
}

.notification-header-main {
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid #ccc;
    margin-bottom: 20px;
}

.tab {
    flex: 1;
    padding: 10px 0;
    cursor: pointer;
    position: relative;
    text-align: center;
}

.tab.active {
    color: #FC9920;
    font-weight: bold;
}

.tab.active::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: #FC9920;
}

.notification-list {
    margin-top: 20px;
}

.notification-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
}

.notification-item.unread {
    background-color: #e6f7ff;
}

.notification-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.notification-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.notification-text {
    display: flex;
    flex-direction: column;
}

.notification-message {
    margin: 0;
    font-size: 14px;
    color: #333;
}

.notification-time {
    margin: 0;
    font-size: 12px;
    color: #999;
}

.notification-menu {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.notification-actions {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10px;
    right: 16px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    z-index: 10;
    width: 240px;
    padding: 10px 0;
}

.notification-actions button {
    background: none;
    border: none;
    padding: 10px 20px;
    text-align: left;
    width: 100%;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.notification-actions button:hover {
    background-color: #f0f0f0;
}

.notification-actions button svg {
    margin-right: 10px;
}

.notification-icon {
    position: relative;
}

.notification-count {
    position: absolute;
    top: -5px;
    right: -10px;
    background: red;
    color: white;
    border-radius: 50%;
    padding: 5px 10px;
    font-size: 12px;
}

/* Styles for no notifications */
.no-notifications {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
}

.no-notifications-image {
    max-width: 200px;
    margin-bottom: 20px;
}

/* Scrollbar styles */
.notification-container::-webkit-scrollbar {
    width: 6px;
}

.notification-container::-webkit-scrollbar-track {
    background: #f0f2f5;
}

.notification-container::-webkit-scrollbar-thumb {
    background: #FC9920;
    border-radius: 3px;
}

.notification-container::-webkit-scrollbar-thumb:hover {
    background: #FC9920;
}

/* Responsive styles */
@media (max-width: 768px) {
    .notification-container {
        width: 100%;
        padding: 15px;
    }

    .notification-title {
        padding: 15px;
    }

    .notification-item {
        flex-direction: row;
        align-items: center;
    }

    .notification-text {
        margin-bottom: 5px;
        flex-grow: 1;
    }

    .notification-time {
        font-size: 10px;
    }

    .notification-menu {
        top: 5px;
        right: 5px;
    }

    .notification-actions {
        width: 240px;
    }

    .notification-actions button {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .notification-container {
        padding: 10px;
    }

    .notification-title {
        padding: 10px;
    }

    .notification-item {
        padding: 8px;
    }

    .notification-message {
        font-size: 12px;
    }

    .notification-time {
        font-size: 8px;
    }

    .notification-actions button {
        font-size: 10px;
    }
}
