.overview {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;
}


.overview .card {
    flex: 1;
    background-color: #fff;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    min-width: 250px;
}

.detailed_report_icon{
    color: #FEAF04;
}


.overview .card p {
    margin: 8px 0;
}

.overview .card span {
    font-size: 14px;
    color: #666;
}

.card-heading{
    display: flex;
    justify-content: space-between;
}

.card-heading-text {

    color: #353434;
    font-weight: 500 !important;
    font-size: 16px !important;
    
}

.card-heading-icon-user{
    color: #379AE6;
    font-size: o.6rem;
} 
.card-heading-icon-comments{
    color: #E05858;
    font-size: o.6rem;
} 
.card-heading-icon-thread{
    color: #4069E5;
    font-size: o.6rem;
} 


.card-content{
    font-size: 30px;
    font-weight: 600;

}


.card-percentage{
    color: rgb(9, 255, 0) !important;
    font-size: 12px !important;
    font-weight: 800 !important;
}