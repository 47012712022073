/* Modal styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.3s ease;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  animation: slideDown 0.5s ease;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
  font-size: 24px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-group {
  margin-bottom: 10px;
  width: 100%;
}

.edit-input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error-text {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
}

.save-button {
  background: #e0f7fa;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  color: #00796b;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.cancel-button {
  background: #f44336;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-20%);
  }
  to {
    transform: translateY(0);
  }
}

/* Your existing styles */
.profile-section {
  padding: 20px;
  color: #333;
  max-width: 1200px;
  background-color: white;
  margin: 0 auto;
  overflow-x: auto;
  transition: margin-left 0.3s ease;
  margin-top: 40px;
}

.general-info {
  margin-top: 25px;
}

.profile-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.profile-info {
  display: flex;
  align-items: center;
}

.profile-pic-container {
  position: relative;
  width: 80px;
  height: 80px;
  margin-right: 20px;
}

.profile-pic {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.edit-profile-pic {
  position: absolute;
  bottom: 0px;
  left: 80%;
  transform: translateX(-50%);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.edit-icon {
  color: black;
  font-size: 16px;
}

.profile-details {
  display: flex;
  flex-direction: column;
}

.profile-details h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
  font-weight: bold;
}

.profile-details p {
  margin: 10px 4px 0;
  color: #888;
  font-size: 14px;
}

.profile-content {
  background: white;
  padding: 20px;
  margin-top: 40px;
  border-radius: 8px;
}

.section {
  margin-bottom: 20px;
  color: #888;
  font-size: 14px;
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.section-header h3 {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #333;
  font-weight: bold;
  margin: 0;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00bcd4;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.section-icon {
  color: white;
  font-size: 18px;
  font-family: Arial, sans-serif;
}

.edit-button {
  background: #e0f7fa;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  color: #00796b;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.edit-button svg {
  margin-right: 5px;
}

.cancel-button {
  background: #ffcccb;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  color: #d32f2f;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: 10px;
}

.info-table {
  width: 100%;
  border-collapse: collapse;
}

.info-table td {
  padding: 8px 0;
  font-size: 14px;
}

.info-table .label {
  width: 30%;
  color: #888;
  padding-right: 10px;
  text-align: left;
}

.info-table .value {
  color: #333;
  text-align: left;
  word-wrap: break-word;
}

.customer-insight {
  background: #e0f7fa;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
}

.status {
  background: #4caf50;
  color: #fff;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  margin-left: 5px;
}

.billing-status {
  background: #8e24aa;
  color: #fff;
  padding: 2px 10px;
  border-radius: 12px;
  margin-left: 10px;
  font-size: 12px;
}

.section-general-icon {
  display: flex;
  align-items: center;
}

.insight-container {
  margin-top: 50px !important;
}

.edit-input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error-text {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  list-style-type: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination li a {
  cursor: pointer;
  padding: 8px 16px;
  border: 1px solid #ddd;
  color: #007bff;
  text-decoration: none;
}

.pagination li a:hover {
  background-color: #007bff;
  color: white;
}

.pagination .active a {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.delete-icon {
  color: red;
  cursor: pointer;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-20%);
  }
  to {
    transform: translateY(0);
  }
}

.profile-section .fade-in {
  animation: fadeIn 0.5s forwards;
}

.profile-section .fade-out {
  animation: fadeOut 0.5s forwards;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .profile-info {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .profile-pic-container {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .edit-profile-pic {
    bottom: -5px;
    left: 50%;
  }

  .section-general-icon {
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .section-header h3 {
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  .profile-details h2 {
    font-size: 20px;
  }

  .profile-details p {
    font-size: 12px;
  }

  .info-table .label, .info-table .value {
    display: table-cell; /* Maintain table structure */
  }

  .info-table .label {
    width: 30%; /* Maintain label width */
  }

  .edit-button, .cancel-button {
    margin: 0 10px;
    flex: 1;
    width: auto; /* Buttons take equal width and are side by side */
  }

  .edit-button {
    margin-left: 0;
  }

  .cancel-button {
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .profile-section {
    padding: 10px;
  }

  .profile-details h2 {
    font-size: 18px;
  }

  .profile-details p {
    font-size: 12px;
  }

  .edit-button, .cancel-button {
    font-size: 12px;
  }

  .section-header h3 {
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .icon-container {
    width: 25px;
    height: 25px;
  }

  .section-icon {
    font-size: 14px;
  }
}

.twitter-button{
  padding: 10px;
  font-weight: 700;
  color: white;
  background-color: black;
  border-radius: 5px;
  cursor: pointer;
}

.verified-icon{
  
  color: #007bff;
}
