.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 40px !important;
    background-color: #0A0A0A;
    color: white;
    box-sizing: border-box;
}

.navbar-logo {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.logo-title{
    font-size: 0.9rem;
    font-weight: 400;
}

.navbar-hamburger {
    display: none;
    font-size: 24px;
    cursor: pointer;
}

.navbar-links {
    list-style: none;
    display: flex;
    gap: 40px;
    margin: 0;
    padding: 0;
}

.navbar-links li {
    display: inline;
    position: relative;
}

.navbar-links a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    padding: 10px;
    box-sizing: border-box;
    display: block;
    border-radius: 5px;
}

.navbar-links a:hover {
    text-decoration: none;
    background-color: #F38E14;
}

.navbar-button {
    background-color: #F38E14;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
}

.navbar-button:hover {
    background-color: #FEAF04;
}

.mobile-only {
    display: none;
}

.desktop-only {
    display: block;
}

.notification-badge {
    position: absolute;
    top: -5px;
    right: -10px;
    background: red;
    color: white;
    border-radius: 50%;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
}


/* Responsive styles */
@media (max-width: 768px) {
    .navbar {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        position: relative;
        z-index: 10;
    }

    .navbar-logo.hide {
        display: none;
    }

    .navbar-hamburger {
        display: block;
        font-size: 24px;
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 10px;
    }

    .navbar-links {
        display: none;
        flex-direction: column;
        width: 100%;
        text-align: center;
        position: absolute;
        top: 66px;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
        padding: 20px;
        box-sizing: border-box;
    }

    .navbar-links.open {
        display: flex;
    }

    .navbar-links li {
        width: 100%;
        padding: 10px 0;
    }

    .navbar-links a {
        padding: 10px 20px;
        width: 100%;
    }

    .navbar-button {
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }

    .mobile-only {
        display: block;
    }

    .desktop-only {
        display: none;
    }
}

@media (max-width: 480px) {
    .navbar {
        padding: 15px 20px;
        z-index: 10;
    }

    .navbar-logo {
        font-size: 20px;
    }

    .navbar-logo.hide {
        display: none;
    }

    .navbar-links {
        top: 50px;
    }

    .navbar-links a {
        font-size: 16px;
        padding: 8px 20px;
    }

    .navbar-button {
        font-size: 16px;
        padding: 8px 15px;
    }
}
