.main-content {
    max-width: 1200px;
    padding: 20px;
    background-color: white;
    margin: 0 auto;
    overflow-x: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: margin-left 0.3s ease;
    margin-top: 40px;
    z-index: 10000;
}

.main-content h1 {
    font-family: "Epilogue", sans-serif;
    color: #333;
    margin-bottom: 20px;
}

.search-container-dashboard{
    width: 500px;
   
}

.search-operations-container{
    display: flex;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .main-content {
        padding: 10px;
    }
}

.club-operation-container{
    display: flex;
}