/* Modal overlay styling */
.club-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s;
    z-index: 1000;
}

/* Modal content styling */
.club-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%; /* Adjust width as needed */
    max-height: 90vh; /* Constrain the height */
    position: relative;
    display: flex;
    flex-direction: column;
    animation: slideIn 0.4s ease-out;
    z-index: 1001;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Modal inner content area to enable scrolling */
.modal-content {
    flex: 1;
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */
    padding-right: 10px; /* Adjust padding to avoid scrollbar overlap */
}

/* Custom scrollbar styling */
.modal-content::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
}

.modal-content::-webkit-scrollbar-track {
    background: #f4f4f4; /* Background of the scrollbar track */
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: orange; /* Scrollbar thumb (handle) color */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
    border: 2px solid transparent; /* Space between thumb and track */
}

/* Close button styling */
.club-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    border: none;
    background: none;
    color: #333;
}

/* Club button styling */
.club-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: orange;
    border: none;
    color: white;
    border-radius: 5px;
    margin-left: 10px;
}

/* Input section styling */
.club-input-section {
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100%;
}

/* Input fields styling */
.add-url-input {
    font-size: 14px;
    width: 100%;
    padding: 10px;
    border: 1px solid #a19595;
    border-radius: 4px;
    height: 50px;
    margin-right: 10px;
    margin-top: 10px;
}

/* Add button styling */
.club-save-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: orange;
    border: none;
    color: white;
    border-radius: 5px;
    margin-left: 10px;
}

/* Cancel button styling */
.cancel-button {
    padding: 10px 20px;
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
    background-color: #ccc;
    border: none;
    color: #333;
    border-radius: 5px;
    transition: background-color 0.3s;
}

/* Table container with scroll */
.clubbed-urls-table-container {
    flex: 1;
    overflow-y: auto;
    margin-top: 10px;
    width: 100%;
}

/* Table styling */
.clubbed-urls-custom-table {
    width: 100%;
    border-collapse: collapse;
}

.clubbed-urls-custom-table th,
.clubbed-urls-custom-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.clubbed-urls-custom-table th {
    background-color: #f4f4f4;
}

/* Delete button styling */
.club-delete-button {
    border: none;
    background: none;
    color: red;
    font-size: 18px;
    cursor: pointer;
}

/* Animation for fade-in effect */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Animation for slide-in effect */
@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
