.operations-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s;
    z-index: 1000;
    
  }
  
  /* Modal content styling */
  .operations-modal-content {
    background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 800px;
  height: 600px; /* Fixed height to allow space for scrolling */
  position: relative;
  display: flex;
  flex-direction: column;
  animation: slideIn 0.4s ease-out;
  z-index: 1001;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Close button styling */
  .operations-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    border: none;
    background: none;
    color: #333;
  }



/* Table container with scroll */
.operations-table-container {
  flex: 1;
  overflow-y: auto;
  margin-top: 10px;
  width: 100%;
  max-height: 400px; /* Adjust max-height to control scroll area */
  padding: 5px; /* Optional padding */
  border: 1px solid #ddd; /* Optional border */
  border-radius: 4px; /* Optional rounded corners */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1); /* Optional inner shadow */
}

/* Table styling */
.operations-custom-table {
  width: 100%;
  border-collapse: collapse;
}

.operations-custom-table th,
.operations-custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.operations-custom-table th {
  background-color: #f4f4f4;
}

/* Scrollbar styling (optional) */
.operations-table-container::-webkit-scrollbar {
  width: 8px;
}

.operations-table-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

  
  /* Button styling */
  .operations-button {
    padding: 10px 20px;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
    background-color: orange;
    border: none;
    color: white;
    border-radius: 5px;
  }
  
  /* Input section styling */
  .input-section {
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100%;
  }
  
  .domain-input {
    flex: 1;
    font-size: 14px;
    width: 100%;
    padding: 10px 10px 10px 30px; 
    border: 1px solid #a19595;
    border-radius: 4px;
    height: 50px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  }
  
  .add-button {
    width: 80px;
    padding: 10px 0;
    background-color: orange;
    border: none;
    color: white;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }
  
  
  /* Table container with scroll */
  .table-container {
    flex: 1;
    overflow-y: auto;
    margin-top: 10px;
    width: 100%;
  }
  
  /* Table styling */
  .operations-custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .operations-custom-table th,
  .operations-custom-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .operations-custom-table th {
    background-color: #f4f4f4;
  }
  
  /* Delete button styling */
  .operations-delete-button {
    border: none;
    background: none;
    color: red;
    font-size: 18px;
    cursor: pointer;
  }
  
  /* Animation for fade-in effect */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Animation for slide-in effect */
  @keyframes slideIn {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  