.text-box-container {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 5px;
    background-color: #f0f0f0;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    border-radius: 10px;
    margin-top: -12px;
}

.text-box-input {
    flex: 1;
    padding: 10px;
    border-radius: 25px;
    border: 1px solid #ccc;
    outline: none;
    font-size: 16px;
    color: black; /* Ensure text is black */
    background-color: white;
}

.text-box-input:empty:before {
    content: attr(data-placeholder);
    color: gray; /* Placeholder text color */
}

.emoji-icon {
    margin-left: 10px;
    cursor: pointer;
    font-size: 20px;
    color: black;
    border-radius: 50%;
    background-color: yellow;
}

.emoji-picker-container {
    position: absolute;
    bottom: 50px;
    right: 10px;
    z-index: 1000;
}

.send-icon {
    margin-left: 10px;
    cursor: pointer;
    font-size: 24px;
    color: #03a9f4;
}

.text-box-container {
    display: flex;
    align-items: center;
}

.send-icon {
    font-size: 24px;
    margin-left: 10px;
    cursor: pointer;
    color: rgba(47, 47, 235, 0.91);
}

.send-icon.reply-active {
    color: orange; /* Change color to orange when replying */
}

.cancel-icon {
    font-size: 24px;
    margin-left: 10px;
    cursor: pointer;
    color: #f44336; /* Red color for cancel icon */
}

.cancel-icon:hover {
    color: #d32f2f; /* Darker red on hover */
}
