/* Base Styles */
.insights-list {
    width: 100%;
    height: 100%;
    overflow: hidden; /* This will ensure no scrollbars appear */
    padding: 20px;
}

.insights-list h2 {
    margin-top: 20px;
    color: #333;
    font-size: 1.5rem; 
    text-align: center;
}

.insight-container {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden; /* Disable horizontal scrolling */
    padding-right: 5px;
}

/* Insight Item Styles */
.insight-item {
    display: flex;
    align-items: center;
    background: #fff;
    margin-bottom: 8px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 16px;
    flex-direction: row;
    transition: transform 0.2s; /* Smooth transition for hover effect */
}

.insight-item:hover {
    transform: scale(1.02); /* Slightly enlarge on hover for better interactivity */
}

.insight-logo {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    border-radius: 50%;
    object-fit: cover;
}

.insight-details {
    flex: 1;
}

/* Link Styles */
.insight-links a {
    display: block;
    color: #0056b3;
    text-decoration: none;
    margin-bottom: 2px;
    word-break: break-all; /* Ensure long URLs break properly */
}

.insight-links a:hover {
    text-decoration: underline; /* Underline on hover for better UX */
}

.insight-comments {
    color: #333;
    font-size: 0.875rem; /* Responsive font size */
}

/* Scrollbar Styles */
.insight-container::-webkit-scrollbar {
    width: 6px;
}

.insight-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.insight-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}

.insight-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Short URL Style */
.insight-short-url {
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 1rem;
    cursor: pointer;
    color: #EC7906 !important;
}
.insight-short-url:hover {
    text-decoration: underline;

}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .insight-item {
        padding: 15px;
        margin-bottom: 6px;
    }

    .insight-logo {
        width: 50px;
        height: 50px;
        margin-right: 15px;
    }

    .insight-links a {
        font-size: 0.875rem; /* Adjust font size for smaller screens */
    }

    .insight-comments {
        font-size: 0.75rem; /* Adjust font size for smaller screens */
    }
}

@media (max-width: 480px) {
    .insights-list h2 {
        font-size: 1.25rem; /* Smaller font size for very small screens */
    }

    .insight-item {
        padding: 10px;
        margin-bottom: 5px;
    }

    .insight-logo {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }

    .insight-links a {
        font-size: 0.75rem; /* Smaller font size for very small screens */
    }

    .insight-comments {
        font-size: 0.625rem; /* Smaller font size for very small screens */
    }
}

/* No Content Styles */
.no-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.no-content-image {
    width: 100px;
    margin-bottom: 10px;
}

.no-content p {
    color: #666;
    font-size: 14px;
}
