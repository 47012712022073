.follow-container {
    margin-right: 5px;
    display: inline-block;
}

.follow-button {
    padding: 10px 20px;
    color: white;
    background-color: #0095F6;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 14px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}


.follow-button:active {
    transform: scale(0.95);
}

.follow-button.following {
    background-color: #8e8e8e;
}

.follow-button.following:hover {
    background-color: #6c6c6c;
}

