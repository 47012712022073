/* Base Styles */
.comments_container {
    background: #e0e1e3;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    height: 278px;
    overflow-y: auto;
    padding: 5px;
    width: 390px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 6px;
}

.comment_item {
    width: 100%;
    background: #FFF;
    padding: 12px;
    border-radius: 8px;
    margin-top: 10px;
    margin-left: 5px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    width: 98%;
}

.comment_heading {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
}

.profile_pic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.comment_body {
    width: calc(100% - 40px);
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.comment_author {
    font-weight: bold;
    color: black !important;
    font-size: 0.8rem;
    margin: 0;
    cursor: pointer;
    text-decoration: none;
}

.comment_content {
    font-size: 1rem;
    margin-top: 5px;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.comment_actions {
    font-size: 0.8rem;
    color: #666666;
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.like_button, .dislike_button, .reply_button {
    color: #007BFF;
    cursor: pointer;
    font-size: 0.7rem;
    display: flex;
}

.spam_button, .delete_button{
    cursor: pointer;
}

.like-dislike-number{
    margin-top: 6px;
    margin-right: 2px;
}
.comment_replies {
    border-left: 2px solid #f0f2f5;
    margin-left: 20px;
    padding-left: 10px;
}

/* Custom scrollbar styles */
.comments_container::-webkit-scrollbar {
    width: 6px;
}

.comments_container::-webkit-scrollbar-track {
    background: #f0f2f5;
}

.comments_container::-webkit-scrollbar-thumb {
    background: #FC9920;
    border-radius: 3px;
}

.comments_container::-webkit-scrollbar-thumb:hover {
    background: #FC9920;
}

.comment_date {
    font-size: 0.5rem;
    color: #888;
    margin-top: 0px;
}

.comment_header{
    display: flex;
    justify-content: space-between;
}

.comment-url {
    background-color: #EFEFEF;
    text-decoration: none;
    color: #888;
    font-size: 0.6rem;
    margin-left: 4px;
    padding: 4px;
    border-radius: 3px;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide the overflow */
    text-overflow: ellipsis; /* Add ellipsis (...) when the text overflows */
    max-width: 150px; /* Set a maximum width for the URL */
    display: inline-block; /* Display inline but respect width */
    vertical-align: middle; /* Align vertically in case of text wrapping */
}

.verified-icon{
    margin-left: 2px;
    color: #007BFF;
}

@media (max-width: 768px) {
    .comment-url {
        max-width: 120px; /* Adjust max-width for smaller screens */
    }
}

@media (max-width: 480px) {
    .comment-url {
        max-width: 90px; /* Further adjust max-width for very small screens */
    }
}


/* Responsive Adjustments */
@media (max-width: 768px) {
    .comments_container {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 10px;
    }

    .comment_item {
        padding: 10px;
    }

    .comment_heading {
        margin-bottom: 6px;
    }

    .profile_pic {
        width: 25px;
        height: 25px;
        margin-right: 8px;
    }

    .comment_body {
        width: calc(100% - 33px);
    }

    .comment_author, .comment_content, .comment_actions {
        font-size: 0.75rem;
    }

    .like_button, .dislike_button, .reply_button {
        font-size: 0.65rem;
    }

    .comment_replies {
        margin-left: 15px;
        padding-left: 8px;
    }
}

@media (max-width: 480px) {
    .comments_container {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 8px;
    }

    .comment_item {
        padding: 8px;
    }

    .comment_heading {
        margin-bottom: 4px;
    }

    .profile_pic {
        width: 20px;
        height: 20px;
        margin-right: 6px;
    }

    .comment_body {
        width: calc(100% - 26px);
    }

    .comment_author, .comment_content, .comment_actions {
        font-size: 0.7rem;
    }

    .like_button, .dislike_button, .reply_button {
        font-size: 0.6rem;
    }

    .comment_replies {
        margin-left: 10px;
        padding-left: 6px;
    }
}
