body {
    padding: 0;
    margin: 0;
    font-family: 'Inter', sans-serif;
    background-image: url('./assets/background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}



@media (max-width: 768px) {
    body {
        background-size: auto; 
    }
}

@media (max-width: 480px) {
    body {
        background-size: 100%; 
        
    }
}

.fade-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  .fade-exit {
    opacity: 1;
    transform: scale(1);
  }
  .fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  
