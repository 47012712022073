.footer-bottom {
    background-color: #000;
    text-align: center;
    padding: 10px;
    width: 100%;
}

.footer-bottom p {
    color: #fff;
    font-size: 1rem;
    ;
}