.footer {
    background-color: #F38E14;
    color: #000;
    padding: 150px 0; /* Expanded height */
    text-align: left;
    width: 100%;
    margin-top: 60px;
}
  
.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items to the top */
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-section {
    flex: 1;
    min-width: 250px;
    margin: 10px;
}

.footer-logo {
    margin-bottom: 20px;
}

.footer-logo-image {
    width: 100px; /* Adjust the width of the image as needed */
    margin-bottom: 10px; /* Add some space below the image */
}

.footer-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 5px;
}

.footer-section h3 {
    font-size: 1.2em;
    margin-bottom: 15px;
}

.footer-heading {
    font-weight: bold; 
    margin-top: 25px; 
}

.footer-section p {
    margin: 5px 0;
    font-size: 1rem;
}

.footer-section a {
    color: #000;
    text-decoration: none;
}

.footer-section a:hover {
    text-decoration: underline;
}

.subscribe-form {
    display: flex;
    flex-direction: column;
}

.subscribe-input-container {
    display: flex;
    align-items: center;
}

.subscribe-form label {
    margin-bottom: 10px;
}

.subscribe-form input {
    padding: 10px;
    border: 1px solid #000;
    border-radius: 5px 0 0 5px;
    flex: 1;
}

.subscribe-form button {
    padding: 10px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}

.subscribe-form button:hover {
    background-color: #333;
}

.social-media-icons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.social-media-icons a {
    color: #000;
    font-size: 1.5em;
}

.social-media-icons a:hover {
    color: #555;
}
